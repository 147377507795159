//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from 'quasar'
import shoppingGuideProducts from '../shoppingGuideProducts/Home.vue'
import verifycoupon from '../verifycoupon/Index.vue'
export default {
  name: "Home",
  data() {
    return {
      menu: [],
      loading: false,
      directly: false,
      tabActive: 0,
      taskList: [],
      productCode: "",
      scanCode: "",
      storeOption: [],
      currentStore: "",
      footActive: 0,
      userInfo: {},
      showbody: true,
      completeRate:'',//任务完成率
      sum:'',//当期总任务数
      title:'',//用户角色身份
    };
  },
  components: {},
  created() {

  },
  methods: {
    goDetail(item) {
      let _this = this
      if (item.taskInfo.taskType == 'birthday') {
        this.$router.push('/taskManage/birthDetail?id=' + item.id)
      }else if (item.taskInfo.taskType == 'assess') {
        this.axios
        .get(
          "/worktask/wxcp/task/getRecord?id=" + item.id
        )
        .then((res) => {
          // this.$toast.clear();
          console.log(res)
          if(res.data.data && res.data.data.inputMap && res.data.data.inputMap[0] && res.data.data.inputMap[0].modelId){
            _this.$router.push('/storeManagement/placesadjustment?assessId=' + res.data.data.inputMap[0].modelId)
          }
        });
      } else {
        this.$router.push('/taskManage/detail?id=' + item.id)
      }
    },
    tabClick(index) {
      this.tabActive = index;
    },
    getStore() {
      this.$toast.loading({
        message: "门店获取中",
        forbidClick: true,
        duration: 0,
      });
      const that = this;
      this.storeOption = [];
      // this.axios.get('/v1/wx/cp/getUserInfo').then(res=>{
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log("门店", res);
          this.$toast.clear();
          this.userInfo = res.data.data;
          if (res && res.data && res.data.data && res.data.data.ownerShops) {
            let temp = res.data.data.ownerShops.split(",");
            that.currentStore = temp[0];
            temp.forEach((item) => {
              that.storeOption.push({ text: item, value: item });
            });
            that.getShopTaskInfo()
          } else {
            this.$toast({ message: "获取门店失败", duration: 5000 });
          }
        });
    },
    getShopTaskInfo(e){
      let _this = this;
      this.axios
        .post("/worktask/wxcp/task/getManageNote?storeId=" + this.currentStore, {
        })
        .then((res) => {
          console.log(res);
          if (res && res.data && res.data.data) {
            _this.sum = res.data.data.sum
            _this.title = res.data.data.shopkeeper && res.data.data.shopkeeper.title || "店员"
            _this.completeRate = _this.sum == 0 ? '100%' : ((res.data.data.currCompleted * 100 / res.data.data.sum).toFixed(0) + '%')
          }
        });
    },
    footTabClick(index) {
      this.footActive = index;
    },

    linkTo(url) {
      this.$router.push(url)
    },
    scanStoreClick() {
      const that = this;
      wx.scanQRCode({
        desc: "扫描货品条码",
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function (res) {
          // 回调
          that.productCode = res.resultStr;
          that.$router.push(
            "/stockcheck/detail?code=" +
            that.productCode.toUpperCase() +
            "&shop=" +
            that.currentStore
          );
        },
        error: function (res) {
          if (res.errMsg.indexOf("function_not_exist") > 0) {
            alert("版本过低请升级");
          }
        },
      });
    },
    scanGoodsClick() {
      console.log(shoppingGuideProducts)
      shoppingGuideProducts.methods.handleScan();

    },
    scanCouponClick() {
      verifycoupon.methods.scanClick()
    },
    navigatorTo() {
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxee07e0adf516838a&redirect_uri=https%3A%2F%2Fstoreanalysis.baoxiniao.com%2Fdirect%2F%3Fappname%3Dqywx_appzbxn04&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect'
    },
    wxConfig(callback) {
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getJsSdkConfig", {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: temp.appId, // 必填，企业微信的corpID
              timestamp: temp.timestamp, // 必填，生成签名的时间戳
              nonceStr: temp.nonceStr, // 必填，生成签名的随机串
              signature: temp.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            wx.ready(function () {
              callback && callback();
            });
          } else {
            this.$toast({ message: "调用微信插件失败！", duration: 5000 });
          }
        });
    },
    getTaskList() {
      this.$toast.loading({
        message: "数据加载中",
        forbidClick: true,
        duration: 0,
      });
      this.axios
        .post("/" + this.$ajaxPrefix.worktask + "/wxcp/task/recordList", {
          "pager": {
            "pageIndex": 0,
            "pageSize": 0,
            "rowsCount": 0,
            "start": 0
          },
          "queryMap": {
            "taskRecordStatus": "waiting"
          },
          "queryMapObject": {}
        })
        .then((res) => {
          console.log("任务", res);
          this.$toast.clear();

          if (res && res.data && res.data.data) {
            this.taskList = res.data.data.list
            console.log('this.taskList', this.taskList)
          } else {
            this.$toast({ message: "获取任务失败", duration: 5000 });
          }
        });
    }
  },
  mounted() {
    this.getStore()
    this.getTaskList()
    this.wxConfig()
  },
};
